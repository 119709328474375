<form #form="ngForm">
  <div class="modal-header">
    <h4 class="modal-title">Datum wählen</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #form="ngForm"></form>
    <div class="form-group">
      <label>Datum</label>
      <input type="date" id="date" name="date" [(ngModel)]="date" class="form-control">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="submit()">Starten</button>
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('abort')">Abbrechen</button>
  </div>
</form>
