import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-input-modal',
  templateUrl: './date-input-modal.component.html',
})
export class DateInputModalComponent implements OnInit {

  date: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.activeModal.close(this.date);
  }

}
