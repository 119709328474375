import {Injectable} from '@angular/core';
import {MasterData} from '../models/masterdata';
import { HttpClient } from '@angular/common/http';
import {Endpoints} from './endpoints';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {

  constructor(private httpClient: HttpClient) {
  }

  getVatPositions(masterData: MasterData[]) {
    return masterData.filter(data => data.type === 'VATPOSITION');
  }

  getUnityOptions(masterData: MasterData[]) {
    return masterData.filter(data => data.type === 'UNITYPOSITION');
  }

  getBookholdPositions(masterData: MasterData[]) {
    return masterData.filter(data => data.type === 'BOOKHOLDPOSITION');
  }

  getPaymentTypes(masterData: MasterData[]) {
    return masterData.filter(data => data.type === 'PAYMENTTYPE');
  }

  getRepeattypes(masterData: MasterData[]) {
    return masterData.filter(data => data.type === 'REPEATTYPE');
  }

  getAll(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.masterdata;
    return this.httpClient.get<MasterData[]>(url);
  }

  findAllVatPositions(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.vatpositions;
    return this.httpClient.get<MasterData[]>(url);
  }

  findAllBookholdpositions(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.bookholdpositions;
    return this.httpClient.get<MasterData[]>(url);
  }

  findAllRepeattypes(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.repeattypes;
    return this.httpClient.get<MasterData[]>(url);
  }

  findAllPaymenttypes(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.paymenttypes;
    return this.httpClient.get<MasterData[]>(url);
  }

  findAllUnityOptions(): Observable<MasterData[]> {
    const url = Endpoints.baseUrl + Endpoints.unityoptions;
    return this.httpClient.get<MasterData[]>(url);
  }
}
